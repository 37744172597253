<div class="d-none d-lg-block d-xl-block">
    <div id="sidebar-container" class="bg-primary">
      <div class="logo">
        <h4>Control</h4>
        <p>Software de Gestión</p>
      </div>
      <div class="menu">
        <a [routerLink]="['/administrador']" class="item d-block text-light p-3"><i class="fa-solid fa-list-check"></i> Administrador</a>
        <a [routerLink]="['/ventas']" class="item d-block text-light p-3"><i class="fa-solid fa-hand-holding-dollar"></i> Ventas</a>
        <a [routerLink]="['/slider']" class="item d-block text-light p-3"><i class="fa-regular fa-images"></i> Slider</a>
        <a [routerLink]="['/cupones']" class="item d-block text-light p-3"><i class="fa-solid fa-tags"></i> Cupones</a>
        <a [routerLink]="['/comentarios']" class="item d-block text-light p-3"><i class="fa-solid fa-message"></i> Comentarios</a>
        <a [routerLink]="['/dolar']" class="item d-block text-light p-3"><i class="fa-solid fa-money-check-dollar"></i> Dolar</a>
      </div>
    </div>
  </div>
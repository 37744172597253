<div class="d-none d-lg-block d-xl-block cnt">
    <div class="d-flex ">
      <app-menu></app-menu>
      <div class="w-100">
        <app-head></app-head>
        <div id="contenido">
          <section class="py-3">
            <div class="container-fluid">
                <div class="col-lg-12 mt-5">
                    <div class="row">
                        <div class="col-6">
                            <h1>Listado de juegos</h1>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-ligth" (click)="cargarPs4()"><img src="../../../assets/img/ps4-logo.jpeg" width="200" alt=""></button>
                            <button class="btn btn-ligth" (click)="cargarPs5()"><img src="../../../assets/img/logo-ps5.jpeg" width="200" alt=""></button>
                            <button class="btn btn-ligth" (click)="cargarFisicos()"><img src="../../../assets/img/logpPS.jpeg" width="200" alt=""></button>
                        </div>
                    </div>
                   
                    <hr>
                    <div class="row">
                        <div class="form-group col-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                                </div>
                                <input type="text" class="form-control" [(ngModel)]="filterProducto" name="filterProducto" placeholder="Buscar producto..." aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col text-right">
                            <button class="btn actionbtnone" [routerLink]="['/producto/nuevo']">
                                <i class="fa fa-plus"></i>
                                Agregar
                            </button>
                        </div>
                    </div>
                    <table class="table mt-3 table-sm">
                        <thead style="background-color: #2B2E57;color:#FFF;font-size: 13px;">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Principal</th>
                                <th scope="col">Secundario</th>
                                <th scope="col">$ Precio</th>
                                <th scope="col">% Oferta</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody class="text-center" style="font-size: 12px;">
                            <tr *ngFor="let producto of juegos  | filter:filterProducto">
                                <td><img [src]="producto.imageProd" class="img-fluid img-thumbnail" alt="..."> </td>
                                <td>{{producto.nombre}}</td>
                                <td>{{producto.tipo}}</td>
                                <td>{{producto.cantPpal}}</td>
                                <td>{{producto.cantSec}}</td>
                                <td>${{producto.precio | number}}</td>
                                <td>{{producto.oferta}}%</td>
                                <td>
                                    <button class="btn actionbtnone btn-sm mr-1" title="Editar" [routerLink]="['/editar', producto.id]">
                                        <i class="fa fa-pencil"></i>
                                    </button>
                                    <button class="btn actionbtntwo btn-sm mr-1" title="Eliminar" (click)="borrarProducto(producto)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          </section>
         </div>
      </div>
    </div>
  </div>
<app-navbar (selectCurrency)="divisaSelected($event)"></app-navbar>
<div *ngIf="!load">
    <div class="col-md-12">
        <div class="d-flex justify-content-center carga">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <img src="../../../assets/img/logo.png" alt="" width="100">
        </div>
    </div>
</div>
<div *ngIf="load">
    <section class="slder d-none d-lg-block d-xl-block">

        <div id="myCarousel" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#myCarousel" data-slide-to="0"></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
                <li data-target="#myCarousel" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img [src]="imagen3" class="d-block w-101" alt="" width="100%">
                    <div class="container">
                        <div class="carousel-caption text-left">
                            <h1></h1>
                            <p></p>
                            <p><a class="btn btn-lg btn-dark slde" [routerLink]="['/registro']" routerLinkActive="router-link-active" role="button">REGISTRARSE</a></p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <img [src]="imagen1" class="d-block w-101" alt="..." width="100%">
                    <div class="container">
                        <div class="carousel-caption">
                            <h1></h1>
                            <p></p>
                            <p><a class="btn btn-lg btn-dark slde" (click)="openCatalogoAll()" role="button">CATÁLOGO</a></p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <img [src]="imagen2" class="d-block w-101" alt="..." width="100%">
                    <div class="container">
                        <div class="carousel-caption text-right">
                            <h1></h1>
                            <p></p>
                            <p><a class="btn btn-lg btn-dark slde" (click)="openCatalogInOffer()" role="button">OFERTAS</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>

         
    </section>
    <section class="movil d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="{{imagen3}}" class="d-block w-100" alt="...">
                </div>
                <div class="carousel-item">
                    <img src="{{imagen1}}" class="d-block w-100" alt="...">
                </div>
                <div class="carousel-item">
                    <img src="{{imagen2}}" class="d-block w-100" alt="...">
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </section>

    <section id="ps4" class="destacados">
        <!-----------------------titulo ----------------->
        <div class="container-xl">
            <div class="row">
                <div class="col-lg-12 mt-5">
                    <h4><i class="fa fa-star" aria-hidden="true"></i> JUEGOS DESTACADOS
                        <a (click)="openCatalogoAll()" class="allview"> Ver todos <i class="fa fa-chevron-right" aria-hidden="true"></i> </a>
                    </h4>
                    <hr>

                </div>
            </div>
            <!----------------------lista de juegos ------------------->
            <div class="d-none d-lg-block d-xl-block">
                <div class="row row-cols-1 row-cols-md-5">
                    <div class="col mb-3" *ngFor="let juego of juegos">
                        <div class="card h-100 games" (click)="openGame(juego)">
                            <span *ngIf="juego.oferta > 0" class="notify-badge">-{{juego.oferta}}% 0FF</span>
                            <span *ngIf="juego.cantPpal == 0 && juego.cantSec == 0" class="notify-badge2" translate="no">AGOTADO</span>
                            <img [src]="juego.imageProd" class="card-img-top" alt="...">
                            <div class="card-body text-center">
                                <h5 class="card-title">{{juego.nombre | titlecase}}</h5>
                            </div>
                            <div class="card-footer" style="border: none;">
                                <div class="text-center">
                                    <p *ngIf="!dolar" class="card-text"><b>${{juego.precio | number}} C0P <br> ${{juego.preciosec | number}} C0P</b></p>
                                    <p *ngIf="dolar" class="card-text"><b>${{(juego.precio)/usd | number}} USD <br> ${{(juego.preciosec)/usd | number}} USD</b></p>
                                </div>
                                <br>
                                <button class="btn btn-success btn-block">Comprarlo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mvl d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
            <div class="row">
                <div class="col-6 mb-2" *ngFor="let juego of juegos">
                    <div class="card h-100 games" (click)="openGame(juego)">
                        <span *ngIf="juego.oferta > 0" class="notify-badge" translate="no">-{{juego.oferta}}% 0FF</span>
                        <span *ngIf="juego.cantPpal == 0 && juego.cantSec == 0" class="notify-badge2" translate="no">AGOTADO</span>
                        <img [src]="juego.imageProd" class="card-img-top" alt="...">
                        <div class="card-body text-center">
                            <h5 class="card-title">{{juego.nombre | titlecase}}</h5>
                        </div>
                        <div class="card-footer" style="border: none;">
                            <div class="text-center">
                                <p *ngIf="!dolar" class="card-text"><b>${{juego.precio | number}} C0P <br> ${{juego.preciosec | number}} C0P</b></p>
                                <p *ngIf="dolar" class="card-text"><b>${{(juego.precio)/usd | number}} USD <br> ${{(juego.preciosec)/usd | number}} USD</b></p>
                            </div>
                            <br>
                            <button class="btn btn-success btn-block">Comprarlo</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
    <hr>
    <section class="fisicos">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 mt-5">
                    <h4><img src="../../../assets/img/fisico.png" width="25" alt=""> JUEGOS FISICOS Y ACCESORIOS
                        <a style="cursor: pointer;" (click)="openCatalogInProds()" class="allview"> Ver todos <i class="fa fa-chevron-right" aria-hidden="true"></i> </a>
                    </h4>
                    <hr>
                </div>
            </div>
            <br>
            <div class="d-none d-lg-block d-xl-block">
                <div class="row row-cols-1 row-cols-md-5">
                    <div class="col mb-3" *ngFor="let juego of fisicos">
                        <div class="card h-100 games" (click)="openGameFisico(juego)">
                            <span *ngIf="juego.oferta > 0" class="notify-badge">-{{juego.oferta}}% 0FF</span>
                            <span *ngIf="juego.cantPpal == 0 && juego.cantSec == 0" class="notify-badge2" translate="no">AGOTADO</span>
                            <img [src]="juego.imageProd" class="card-img-top" alt="...">
                            <div class="card-body text-center">
                                <h5 class="card-title">{{juego.nombre | titlecase}}</h5>
                            </div>
                            <div class="card-footer" style="border: none;">
                                <div class="text-center">
                                    <p *ngIf="juego.oferta == 0" class="card-text"><b>${{juego.precio | number}} C0P </b></p>
                                    <p *ngIf="juego.oferta > 0" class="card-text"><b>${{juego.precio - ((juego.precio * juego.oferta)/100) | number}} C0P </b></p>
                                </div>
                                <br>
                                <button class="btn btn-info btn-block">Comprarlo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mvl d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
                <div class="row">
                    <div class="col-6 mb-2" *ngFor="let juego of fisicos">
                        <div class="card h-100 games" (click)="openGameFisico(juego.id)">
                            <span *ngIf="juego.oferta > 0" class="notify-badge" translate="no">-{{juego.oferta}}% 0FF</span>
                            <span *ngIf="juego.cantPpal == 0 && juego.cantSec == 0" class="notify-badge2" translate="no">AGOTADO</span>
                            <img [src]="juego.imageProd" class="card-img-top" alt="...">
                            <div class="card-body text-center">
                                <h5 class="card-title">{{juego.nombre | titlecase}}</h5>
                            </div>
                            <div class="card-footer" style="border: none;">
                                <div class="text-center">
                                    <p *ngIf="!dolar" class="card-text"><b>${{juego.precio | number}} C0P <br> ${{juego.preciosec | number}} C0P</b></p>
                                    <p *ngIf="dolar" class="card-text"><b>${{(juego.precio)/usd | number}} USD <br> ${{(juego.preciosec)/usd | number}} USD</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><br>
    </section>
    <section class="ofertas">
        <!-----------------------titulo ----------------->
        <div class="d-none d-lg-block d-xl-block">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mt-5">
                        <h4><i class="fa fa-briefcase"></i> PRODUCTOS EN OFERTA
                            <a style="cursor: pointer;" (click)="openCatalogInOffer()" class="allview"> Ver todos <i class="fa fa-chevron-right" aria-hidden="true"></i> </a>
                        </h4>
                        <hr>
                    </div>
                </div>
                <div class="d-none d-lg-block d-xl-block">
                    <div class="row row-cols-1 row-cols-md-5">
                        <div class="col mb-3" *ngFor="let juego of ofertas1">
                            <div class="card h-100 games" (click)="openGame(juego)">
                                <span *ngIf="juego.oferta > 0" class="notify-badge">-{{juego.oferta}}% 0FF</span>
                                <span *ngIf="juego.cantPpal == 0 && juego.cantSec == 0" class="notify-badge2" translate="no">AGOTADO</span>
                                <img [src]="juego.imageProd" class="card-img-top" alt="...">
                                <div class="card-body text-center">
                                    <h5 class="card-title">{{juego.nombre | titlecase}}</h5>
                                </div>
                                <div class="card-footer" style="border: none;">
                                    <div class="text-center">
                                        <p class="card-text"><b>${{juego.precio - ((juego.precio * juego.oferta)/100) | number}} C0P <br> ${{juego.precio - ((juego.preciosec * juego.oferta)/100) | number}} C0P</b></p>
                                    </div>
                                    <br>
                                    <button class="btn btn-primary btn-block">Comprarlo</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mt-5">
                        <h4><i class="fa fa-briefcase"></i> PRODUCTOS EN OFERTA
                            <a style="cursor: pointer;" (click)="openCatalogInOffer()" class="allview"> Ver todos <i class="fa fa-chevron-right" aria-hidden="true"></i> </a>
                        </h4>
                        <hr>
                    </div>
                </div>
                <div class="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
                    <div class="row row-cols-1 row-cols-md-5">
                        <div class="col-6 mb-3" *ngFor="let juego of ofertas1">
                            <div class="card h-100 games" (click)="openGame(juego)">
                                <span *ngIf="juego.oferta > 0" class="notify-badge">-{{juego.oferta}}% 0FF</span>
                                <span *ngIf="juego.cantPpal == 0 && juego.cantSec == 0" class="notify-badge2" translate="no">AGOTADO</span>
                                <img [src]="juego.imageProd" class="card-img-top" alt="...">
                                <div class="card-body text-center">
                                    <h5 class="card-title">{{juego.nombre | titlecase}}</h5>
                                </div>
                                <div class="card-footer" style="border: none;">
                                    <div class="text-center">
                                       <!--  <p *ngIf="!dolar" class="card-text"><b>${{juego.precio | number}} C0P <br> ${{juego.preciosec | number}} C0P</b></p>
                                        <p *ngIf="dolar" class="card-text"><b>${{(juego.precio)/usd | number}} USD <br> ${{(juego.preciosec)/usd | number}} USD</b></p> -->
                                        <p class="card-text"><b>${{juego.precio - ((juego.precio * juego.oferta)/100) | number}} C0P <br> ${{juego.precio - ((juego.preciosec * juego.oferta)/100) | number}} C0P</b></p>
                                    </div>
                                    <br>
                                    <button class="btn btn-primary btn-block">Comprarlo</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </section>
    <section class="pagos">
        <div class="container mt-5">
            <div class="col-md-12 text-center">
                <br>
                <h2>Todas tus compras 100% <b>Seguras</b></h2>
                <h3>A través de: </h3>
                <img src="../../../assets/img/pagos.png" class="img-fluid" alt="Responsive image">
            </div>
        </div>
        <footer>
            <div class="card-group mt-3 ftr">
                <div class="card">
                    <div class="card-body text-center">
                        <img src="../../../assets/img/logo2.png" width="200" class="img-fluid" alt="Responsive image">
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Regístrate</h5>
                        <p class="card-text">Al tener tu cuenta en EJE GAMER STORE puedes acceder a la comunidad gamer del eje cafetero, ganar bonos de descuentos y grandes promociones.</p>
                        <button class="btn btn-lg btn-outline-light btn-block" [routerLink]="['/registro']" routerLinkActive="router-link-active">Crear cuenta</button>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Contáctanos</h5>
                        <p class="card-text"><i class="fa fa-phone" aria-hidden="true"></i> (057) 312 7010988</p>
                        <p class="card-text"><i class="fa fa-envelope" aria-hidden="true"></i> info@ejegamerstore.com</p>
                        <a href="https://www.instagram.com/ejeplaypereira/" target="_blank" class="btn btn-outline-light reds"><i class="fa-brands fa-instagram fa-lg"></i></a>
                        <a href="https://api.whatsapp.com/send?phone=573127010988" target="_blank" class="btn btn-outline-light reds"><i class="fa-brands fa-whatsapp fa-lg"></i></a>
                    </div>
                </div>
            </div>
        </footer>
    </section>
</div>
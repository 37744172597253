<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" href="#">EJE GAMER STORE</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/administrador']">Administrador <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/ventas']">Ventas</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/slider']">Slider</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/cupones']">Cupones</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/comentarios']">Comentarios</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/dolar']">Dolar</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" (click)="onLogout()">Salir</a>
            </li>
        </ul>
    </div>
</nav>
<div class="d-none d-lg-block d-xl-block cnt">
    <div class="d-flex ">
      <app-menu></app-menu>
      <div class="w-100">
        <app-head></app-head>
        <div id="contenido">
          <section class="py-3">
            <div class="container mt-5">
                <h3><img src="../../../assets/img/newCupon.png" width="70" alt=""> Crear Cupón</h3>
                <hr>
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <input type="text" (change)="nombreCupon($event.target.value)" placeholder="Nombre del cupón" class="form-control">
                            <br>
                            <input type="text" (change)="codigoCupon($event.target.value)" placeholder="Código del cupón" class="form-control">
                            <br>
                            <input type="number" (change)="descuentoCupon($event.target.value)" placeholder="Porcentaje de descuento %" class="form-control">
                            <br>
                            <input type="number" (change)="duracionCupon($event.target.value)" placeholder="Días disponible" class="form-control">
                            <br>
                            <button class="btn actionbtnone btn-block btn-lg" (click)="generaCupon()">
                                Crear Cupón
                            </button>
                        </div>
                     <div class="col-6">
                        <div class="row row-cols-1 row-cols-md-3">
                            <div class="col mb-4" *ngFor="let item of coupons">
                              <div class="card h-100">
                                <div class="card-body text-center">
                                    <img src="../../../assets/img/porciento.png" width="50" alt="">
                                    <h5>{{item.descuento}}%</h5>
                                    <h4 class="card-title">{{item.nombre | titlecase}}</h4>
                                    <p><b>{{item.codigo}}</b></p>
                                    <small>{{item.fecha | date}}</small>
                                    
                                </div>
                                <div class="card-footer">
                                    <button class="btn actionbtntwo btn-block" (click)="eliminaCupon(item.id)">Eliminar</button>
                                </div>
                              </div>
                            </div>
                          </div>
                     </div>

                    </div>
                </div>
            </div>
          </section>
         </div>
      </div>
    </div>
</div> 

<app-navbar></app-navbar>
<div *ngIf="!load">
    <div class="col-md-12">
        <div class="d-flex justify-content-center carga">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <img src="../../../assets/img/logo.png" alt="" width="100">
        </div>
    </div>
</div>
<div *ngIf="load">
    <div class="container-fluid ppal d-none d-lg-block d-xl-block">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-5">
                    <h3 class="titulo">Catálogo de juegos PS5</h3>
                </div>
                <div class="col-4 text-right">
                    <p>Mostrando {{cantidad}} juegos</p>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <select class="form-control" (change)="filtrarBusqueda($event.target.value)">                      
                          <option value="alfabetico">Orden Alfabético</option>
                          <option value="fecha">Mas Recientes</option>
                          <option value="menor">Precio: de menor a mayor</option>
                          <option value="mayor">Precio: de mayor a menor</option>
                        </select>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-3 izq">
                    <form class="form-inline">
                        <input class="form-control sch" [(ngModel)]="filterProducto" name="filterProducto" type="search" placeholder="Buscar juego..." aria-label="Search">
                        <button class="btnshc"><i class="fa fa-search-plus fa-lg"></i></button>
                    </form>
                    <h5 class="titulo mt-3">Filtrar por categoría</h5>
                    <button (click)="btnSearch('Acción')" class="btn btn-primary btn-block">Acción</button>
                    <button (click)="btnSearch('Aventura')" class="btn btn-primary btn-block">Aventura</button>
                    <button (click)="btnSearch('Deportes')" class="btn btn-primary btn-block">Deportes</button>
                    <!-- <button (click)="btnSearch('Fisicos')" class="btn btn-primary btn-block">Juegos Físicos y Accesorios</button> -->
                    <button (click)="btnSearch('Infantil')" class="btn btn-primary btn-block">Infantil</button>
                    <button (click)="btnSearch('Lucha')" class="btn btn-primary btn-block">Lucha</button>
                    <button (click)="loadOffergames()" class="btn btn-primary btn-block">Ofertas</button>
                    <button (click)="btnSearch('Terror')" class="btn btn-primary btn-block">Terror</button>
                    <button (click)="loadAllgames()" class="btn btn-primary btn-block">Todos</button>

                    <form class="mt-3">
                        <div class="form-group">
                            <label for="formControlRange"><h5 class="titulo">Filtrar precio</h5></label>
                            <input type="range" id="slider" class="form-control-range rng" min="0" max="300000" step="1000" value="0" (change)="value($event.target.value)">
                            <div class="text-right mt-3">
                                <label><h5>Selección: <b>${{rango | number}}</b></h5></label><br>
                                <button class="filtrar" (click)="findPrice(rango)">Buscar</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-lg-9">
                    <div>
                        <div class="d-none d-lg-block d-xl-block">
                            <div class="row row-cols-1 row-cols-md-4">
                                <div class="col mb-3" *ngFor="let juego of juegos | filter:filterProducto">
                                    <div class="card h-100 games" (click)="openGame(juego)">
                                        <span *ngIf="juego.oferta > 0" class="notify-badge">-{{juego.oferta}}% 0FF</span>
                                        <span *ngIf="juego.cantPpal == 0 && juego.cantSec == 0" class="notify-badge2" translate="no">AGOTADO</span>
                                        <img loading="lazy" [src]="juego.imageProd" class="card-img-top" alt="...">
                                        <div class="card-body text-center">
                                            <h5 class="card-title">{{juego.nombre | titlecase}}</h5>
                                        </div>
                                        <div class="card-footer" style="border: none;">
                                            <div class="text-center">
                                                <div *ngIf="juego.oferta > 0">                                                   
                                                    <p *ngIf="!dolar" class="card-text"><b>${{juego.precio - ((juego.precio * juego.oferta)/100) | number}} C0P <br> ${{juego.preciosec - ((juego.preciosec * juego.oferta)/100) | number}} C0P</b></p>
                                                    <p *ngIf="dolar" class="card-text"><b>${{(juego.precio)/usd | number}} USD <br> ${{(juego.preciosec)/usd | number}} USD</b></p>
                                                </div>       
                                                <div *ngIf="juego.oferta == 0">
                                                    <p *ngIf="!dolar" class="card-text"><b>${{juego.precio | number}} C0P <br> ${{juego.preciosec | number}} C0P</b></p>
                                                    <p *ngIf="dolar" class="card-text"><b>${{(juego.precio)/usd | number}} USD <br> ${{(juego.preciosec)/usd | number}} USD</b></p>
                                                </div> 
                                            </div>
                                            <br>
                                            <button class="btn btn-success btn-block">Comprarlo</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container d-xs-block d-sm-block d-md-block d-lg-none d-xl-none movil">
        <h3 class="titulo">Catálogo de juegos PS5</h3>
        <hr>
        <div class="col-lg-3 izq">
            <form class="form-inline">
                <input class="form-control sch" [(ngModel)]="filterProducto" name="filterProducto" type="search" placeholder="Buscar juego..." aria-label="Search">
                <button class="btnshc"><i class="fa fa-search-plus fa-lg"></i></button>
            </form>
            <h5 class="titulo mt-3">Filtrar por categoría</h5>
            <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <button (click)="btnSearch('Acción')" class="btn btn-primary btn-block">Acción</button>
                        <button (click)="btnSearch('Aventura')" class="btn btn-primary btn-block">Aventura</button>
                        <button (click)="btnSearch('Deportes')" class="btn btn-primary btn-block">Deportes</button>
                        <button (click)="btnSearch('Fisicos')" class="btn btn-primary btn-block">Físicos</button>
                    </div>
                    <div class="col-6">
                        <button (click)="btnSearch('Infantil')" class="btn btn-primary btn-block">Infantil</button>
                        <button (click)="btnSearch('Lucha')" class="btn btn-primary btn-block">Lucha</button>
                        <button (click)="loadOffergames()" class="btn btn-primary btn-block">Ofertas</button>
                        <button (click)="btnSearch('Rol')" class="btn btn-primary btn-block">Terror</button>

                    </div>
                </div>
                <button (click)="loadAllgames()" class="btn btn-primary btn-block mt-2">Todos</button>
            </div>
            <form class="mt-3">
                <div class="form-group">
                    <label for="formControlRange"><h5 class="titulo">Filtrar precio</h5></label>
                    <input type="range" id="slider" class="form-control-range rng" min="0" max="300000" step="1000" value="0" (change)="value($event.target.value)">
                    <div class="text-right mt-3">
                        <label><h5>Selección: <b>${{rango | number}}</b></h5></label><br>
                        <button class="filtrar" (click)="findPrice(rango)">Buscar</button>
                    </div>
                </div>
            </form>
            <div class="col-12">
                <div class="form-group">
                    <select class="form-control" (change)="filtrarBusqueda($event.target.value)">                      
                      <option value="alfabetico">Orden Alfabético</option>
                      <option value="fecha">Mas Recientes</option>
                      <option value="menor">Precio: de menor a mayor</option>
                      <option value="mayor">Precio: de mayor a menor</option>
                    </select>
                </div>
            </div>
        </div>
        <div>
            <div class="row">
                <div class="col-6 mb-2" *ngFor="let juego of juegos | filter:filterProducto">
                    <div class="card h-100 games" (click)="openGame(juego)">
                        <span *ngIf="juego.oferta > 0" class="notify-badge">-{{juego.oferta}}% 0FF</span>
                        <span *ngIf="juego.cantPpal == 0 && juego.cantSec == 0" class="notify-badge2" translate="no">AGOTADO</span>
                        <img loading="lazy" [src]="juego.imageProd" class="card-img-top" alt="...">
                        <div class="card-body">
                            <h5 class="card-title">{{juego.nombre | titlecase}}</h5>
                        </div>
                        <div class="card-footer" style="border: none;">
                            <div class="text-center">
                                <div *ngIf="juego.oferta > 0">                                                   
                                    <p *ngIf="!dolar" class="card-text"><b>${{juego.precio - ((juego.precio * juego.oferta)/100) | number}} C0P <br> ${{juego.preciosec - ((juego.preciosec * juego.oferta)/100) | number}} C0P</b></p>
                                    <p *ngIf="dolar" class="card-text"><b>${{(juego.precio)/usd | number}} USD <br> ${{(juego.preciosec)/usd | number}} USD</b></p>
                                </div>       
                                <div *ngIf="juego.oferta == 0">
                                    <p *ngIf="!dolar" class="card-text"><b>${{juego.precio | number}} C0P <br> ${{juego.preciosec | number}} C0P</b></p>
                                    <p *ngIf="dolar" class="card-text"><b>${{(juego.precio)/usd | number}} USD <br> ${{(juego.preciosec)/usd | number}} USD</b></p>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
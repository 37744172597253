<div class="container mt-5">
    <div class="col-12">
        <div class="card text-center">
            <h3 class="card-header">Correo electrónico no verificado</h3>
            <div class="card-body">
                <img src="../../../assets/img/logo.png" class="img-fluid" alt="Responsive image">
                <h5 class="card-title">Por revise su bandeja de correo, incluido el spam y confirme por medio del link de EJE GAMER STORE</h5>
                <p class="card-text">Puede reenviar un nuevo correo de verificación, el anterior queda anulado.</p>
                <button (click)="resend()" class="btn btn-primbuttonry pago">Reenviar Correo de Verificación</button><br><br>
                <button (click)="retornar()" class="btn btn-dark pago">Volver a la pagina principal</button>
            </div>
        </div>
    </div>
</div>
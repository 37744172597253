<app-navbar (selectCurrency)="divisaSelected($event)"></app-navbar>
<div *ngIf="!load">
    <div class="col-md-12">
        <div class="d-flex justify-content-center carga">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <img src="../../../assets/img/logo.png" alt="" width="100">
        </div>
    </div>
</div>
<div *ngIf="load">
    <div class="container">
        <div class="col-lg-12">
            <h2><i class="fa fa-star"></i> Nuestras ofertas</h2>
            <hr>
            <div class="row row-cols-1 row-cols-md-4">
                <div class="col mb-4" *ngFor="let item of ofertas">
                    <div class="card" (click)="openGame(item.id)">
                        <span class="notify-badge">-{{item.oferta}}% 0FF</span>
                        <img [src]="item.imageProd" class="card-img-top" alt="...">
                        <div class="card-body">
                            <h5 class="card-title">{{item.nombre | titlecase}}</h5>
                            <p *ngIf="!dolar" class="card-text"><b>{{item.precio | currency}} COP</b></p>
                            <p *ngIf="dolar" class="card-text"><b>{{(item.precio)/usd | currency}} USD</b></p>
                            <p *ngIf="!dolar" class="card-text"><b>{{item.preciosec | currency}} COP</b></p>
                            <p *ngIf="dolar" class="card-text"><b>{{(item.preciosec)/usd | currency}} USD</b></p>
                            <button class="btn btn-primary btn-block">Comprarlo</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
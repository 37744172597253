<app-navbar></app-navbar>
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="col-md-6 mx-auto mt-5">
                <h3>Registro de usuario.</h3>
                <div class="card border-dark mb-3">
                    <div class="card-header text-right">EJE GAMER STORE</div>
                    <div class="card-body text-dark">
                        <h4 class="card-title text-center">Crear Cuenta</h4>
                        <form [formGroup]="registerForm" (ngSubmit)="onRegister(registerForm.value)">
                            <div class="form-group">
                                <label for="email">E-mail</label>
                                <input type="email" class="form-control" placeholder="Ingrese su email" formControlName="email" [class.is-invalid]="emailNoValido">
                                <small class="eml" *ngIf="emailNoValido">Ingrese un email válido</small>
                            </div>
                            <div class="form-group">
                                <label for="password">Contraseña</label>
                                <div class="row" *ngIf="passwordView == false">
                                    <input type="password" class="form-control pass" formControlName="password" [class.is-invalid]="passwordNoValido">
                                    <button type="button" class="btn btn-light viewPass" (click)="viewPassActive()"><i class="fa fa-eye fa-lg"></i></button>
                                    <small class="spn" *ngIf="passwordNoValido">La contraseña debe ser de 8 caracteres mínimo.</small>
                                </div>
                                <div class="row" *ngIf="passwordView == true">
                                    <input type="text" class="form-control pass" formControlName="password" [class.is-invalid]="passwordNoValido">
                                    <button type="button" class="btn btn-light viewPass" (click)="viewPassInActive()"><i class="fa fa-eye-slash fa-lg"></i></button>
                                    <small class="spn" *ngIf="passwordNoValido">La contraseña debe ser de 8 caracteres mínimo.</small>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-success btn-block rgt">Registrarse</button>
                        </form>
                        <div class="text-right mt-2">
                            <p>Ya tengo una cuenta! <a [routerLink]="['/login']">Login</a></p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
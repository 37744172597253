<app-navbar></app-navbar>
<div class="container" style="margin-top: 11rem;">
    <h2>Resumen de la compra:</h2>
    <br>
    <p><b>Referencia de compra: </b>{{prodSvc.datosCompra.refCompra}}</p>
    <p><b>Fecha de compra: </b>{{prodSvc.datosCompra.fecha | date}}</p>
    <p><b>E-mail: </b>{{prodSvc.datosCompra.email}}</p>
    <h3>Productos:</h3>
    <br>
    <p *ngFor="let item of prodSvc.datosCompra.carrito"><img [src]="item.imagen" width="60" alt="">&nbsp; {{item.nombre}}</p>
    <h3>Total:</h3>
    <h1 style="color: rgb(16, 81, 202);">${{prodSvc.dataPayu.liquidacion | number}}</h1>
    <hr>
    <br>
    <h4>Por favor selecciona el método de pago:</h4>
    <br>
    <button class="btn btn-ligth medios" (click)="payu()"><img src="../../../assets/img/payu.png" width="100" alt=""></button>
    <button class="btn btn-ligth medios ml-3 mr-3" (click)="bancolombia()" data-toggle="modal" data-target="#medioPago"><img src="../../../assets/img/bancolombia.png" width="100" alt=""></button>
    <button class="btn btn-ligth medios ml-3 mr-3" (click)="nequi()" data-toggle="modal" data-target="#medioPago"><img src="../../../assets/img/nequi-logo.png" width="100" alt=""></button>
    <button class="btn btn-ligth medios" (click)="daviplata()" data-toggle="modal" data-target="#medioPago"><img src="../../../assets/img/daviplata.png" width="100" alt=""></button>
</div>
 
  <!-- Modal -->
  <div class="modal fade" id="medioPago" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <h4>Por favor abra su aplicación de <b>{{medio | titlecase}}</b> para escanear Qr.</h4>
            <br>
            <div class="text-center" *ngIf="medio == 'bancolombia'">
                <img src="../../../assets/img/bancolombia.png" width="70" alt="">
                <br>
                <img src="../../../assets/img/qrBancolombia.jpeg" width="250" alt="">
            </div>
            <div class="text-center" *ngIf="medio == 'nequi'">
                <img src="../../../assets/img/nequi-logo.png" width="70" alt="">
                <br>
                <img src="../../../assets/img/qrNequi.jpeg" width="250" alt="">
            </div>
            <div class="text-center" *ngIf="medio == 'daviplata'">
                <img src="../../../assets/img/daviplata.png" width="100" alt="">
                <br>
                <img src="../../../assets/img/qrDaviplata.jpeg" width="250" alt="">
            </div>
            <br>
            <h5>{{numCertificacion}}:</h5>
            <input type="text" class="form-control certificar" placeholder="Ingrese comprobante" [(ngModel)]="comprobante">
            <br>
            <button class="btn btn-primary" (click)="compra()" data-dismiss="modal">Confirmar pago</button>
        </div>
      </div>
    </div>
  </div>

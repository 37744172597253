<div class="d-none d-lg-block d-xl-block cnt">
    <div class="d-flex ">
      <app-menu></app-menu>
      <div class="w-100">
        <app-head></app-head>
        <div id="contenido">
          <section class="py-3">
            <div class="container" style="margin-top: 3rem;">
                <h2>Gestión de Juegos:</h2>
                <hr>
                <div class="row animated fadeIn slow mt-5">
                    <div class="col-md-12">
                        <form [formGroup]="newProductForm" (ngSubmit)="addNewProduct(newProductForm.value)" autocomplete="off">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Nombre del juego / Producto:</label>
                                        <input type="text" formControlName="nombre" class="form-control" [class.is-invalid]="nombreNoValido" placeholder="Nombre del producto" name="nombre">
                                        <small *ngIf="nombreNoValido" class="text-danger">Debe ingresar el nombre</small>
                                    </div>
                                    <div class="form-group">
                                        <label>Tipo de juego / Producto</label>
                                        <select class="form-control" formControlName="tipo" name="tipo" (change)="tipoProducto($event.target.value)">                      
                                          <option value="Ps4">Ps4</option>
                                          <option value="Ps5">Ps5</option>
                                          <option value="fisico">Físicos - Accesorios</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Imagen del juego / Producto:</label>
                                        <input type="file" formControlName="imageProd" class="form-control" (change)="handleImage($event)">
                                    </div>
                                    <div class="form-group" *ngIf="!fisicosAct" [class.is-invalid]="categoriaNoValido">
                                        <label>Categoria:</label>
                                        <select class="form-control" formControlName="categoria" name="categoria" > 
                                          <option value="Acción">Acción</option>
                                          <option value="Deportes">Deportes</option>
                                          <option value="Infantil">Infantil</option>
                                          <option value="Aventura">Aventura</option>
                                          <option value="Terror">Terror</option>
                                          <option value="Lucha">Lucha</option>
                                        </select>
                                        <small *ngIf="categoriaNoValido" class="text-danger">Debe seleccionar categoría</small>
                                    </div>
                                    <div class="form-group"  *ngIf="fisicosAct">
                                        <label>Categoria:</label>
                                        <select class="form-control" formControlName="categoria" name="categoria" [class.is-invalid]="categoriaNoValido"> 
                                          <option value="Controles">Controles</option>
                                          <option value="Diademas">Diademas</option>
                                          <option value="Ps5">Juegos PS5</option>
                                          <option value="Ps4">Juegos PS4</option>
                                          <option value="Accesorios">Accesorios</option>
                                          <option value="Consolas">Consolas</option>
                                          <option value="Cables">Cables</option>
                                          <option value="Skins">Skins</option>
                                        </select>
                                        <small *ngIf="categoriaNoValido" class="text-danger">Debe seleccionar categoría</small>
                                    </div>
                                    <div class="form-group">
                                        <label>Idioma:</label>
                                        <select class="form-control" formControlName="idioma" name="idioma" >                      
                                          <option value="Multi Idioma">Multi Idioma</option>
                                          <option value="Textos Español - Audio Ingles">Textos Español - Audio Inglés</option>
                                          <option value="Ingles">Inglés</option>
                                          <option value="N/A">No Aplica</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Oferta del juego / producto:</label>
                                        <input type="number" formControlName="oferta" class="form-control" placeholder="Oferta del juego" name="oferta">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Precio Principal:</label>
                                                <input type="number" formControlName="precio" class="form-control" name="precio" placeholder="Ingrese el precio" [class.is-invalid]="precioNoValido">
                                                <small *ngIf="precioNoValido" class="text-danger">Debe ingresar el precio principal</small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Cantidad Principal:</label>
                                                <input type="number" formControlName="cantPpal" class="form-control" name="cantPpal" placeholder="Ingrese la cantidad principal" [class.is-invalid]="cantPpalNoValido">
                                                <small *ngIf="cantPpalNoValido" class="text-danger">Debe ingresar la cantidad principal</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Precio Secundario:</label>
                                                <input type="number" formControlName="preciosec" class="form-control" name="preciosec" placeholder="Ingrese el precio secundario" [class.is-invalid]="preciosecNoValido">
                                                <small *ngIf="preciosecNoValido" class="text-danger">Debe ingresar el precio secundario</small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Cantidad Secundario:</label>
                                                <input type="number" formControlName="cantSec" class="form-control" name="cantSec" placeholder="Ingrese la cantidad secundario" [class.is-invalid]="cantSecNoValido">
                                                <small *ngIf="cantSecNoValido" class="text-danger">Debe ingresar la cantidad secundario secundario</small>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="form-group">
                                        <label>Peso del juego:</label>
                                        <input type="text" formControlName="peso" class="form-control" name="peso" placeholder="Ingrese el peso en GB" [class.is-invalid]="pesoNoValido">
                                        <small *ngIf="pesoNoValido" class="text-danger">Debe ingresar el peso del juego</small>
                                    </div>
                                    <div class="col-md-12 imgBox">
                                        <img [src]="imageSrc" class="imgSrc" alt="">
                                    </div>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Descripción del juego / producto:</label>
                                    <textarea class="form-control" formControlName="descripcion" [class.is-invalid]="descripcionNoValido" cols="30" rows="5"></textarea>
                                    <small *ngIf="descripcionNoValido" class="text-danger">Debe ingresar la descripción del juego min 30 caracteres</small>
                                </div>
                            </div>
                            <div class="form-group text-center mt-3">
                                <button type="submit" class="btn btn-outline-success w-50">
                                    <i class="fa fa-save"> Guardar</i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div><br>
            </div>
          </section>
         </div>
      </div>
    </div>
</div>

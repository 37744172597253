<nav class="navbar navbar-expand-lg navbar-light bg-light hder">
    <a class="navbar-brand" href="#">
      <img src="../../../../assets/img/logoControl.png" width="100" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
          <a class="nav-link" [routerLink]="['/administrador']" ><i class="fa-solid fa-list-check"></i> Administrador</a>
        </li>
        <li class="nav-item d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
          <a class="nav-link" [routerLink]="['/ventas']" ><i class="icon ion-md-bicycle mr-2 lead"></i> Ventas</a>
        </li>
        <li class="nav-item d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
          <a class="nav-link" [routerLink]="['/slider']" ><i class="icon ion-md-list mr-2 lead"></i> Slider</a>
        </li>
        <li class="nav-item d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
          <a class="nav-link" [routerLink]="['/cupones']" ><i class="icon ion-md-filing mr-2 lead"></i> Cupones</a>
        </li>
        <li class="nav-item d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
          <a class="nav-link" [routerLink]="['/comentarios']" ><i class="icon ion-md-pricetags mr-2 lead"></i> Comentarios</a>
        </li>
        <li class="nav-item d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
            <a class="nav-link" [routerLink]="['/dolar']" ><i class="icon ion-md-pricetags mr-2 lead"></i> dolar</a>
          </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
            <img src="../../../../assets/img/user.png" width="30" class="img-fluid rounded-circle" alt="">
            Admin EjeGamerStore
          </a>
          <div class="dropdown-menu">            
            <a class="dropdown-item" (click)="logout()">Cerrar Sesión</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
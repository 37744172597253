<app-navbar></app-navbar>
<div *ngIf="!load">
    <div class="col-md-12">
        <div class="d-flex justify-content-center carga">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <img src="../../../assets/img/logo.png" alt="" width="100">
        </div>
    </div>
</div>
<div *ngIf="load">
    <div class="container">
        <div class="col-lg-12">
            <h3>
                <img src="../../../assets/img/heart.png" class="img-fluid" alt="Responsive image"> Mis Juegos Favoritos:
            </h3>
            <hr>
            <div>
                <div class="row row-cols-1 row-cols-md-4">
                    <div class="col mb-3" *ngFor="let juego of juegos">
                        <div class="card h-100 games">
                            <img [src]="juego.imagen" class="card-img-top" alt="...">
                            <span *ngIf="juego.oferta > 0" class="notify-badge">-{{juego.oferta}}% 0FF</span>
                            <div class="card-body">
                                <div class="text-center">
                                    <p class="card-title">{{juego.nombre | titlecase}}</p>
                                </div>
                                <div class="text-right">
                                    <button class="btn btn-light" (click)="borrarFavorito(juego.id)" title="Eliminar de favoritos"><i class="fa fa-trash fa-lg"></i></button>
                                </div>
                                <div>
                                    <button class="btn btn-primary btn-block" (click)="openGame(juego.juego)">Ver Juego</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
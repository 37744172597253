<app-navbar></app-navbar>
<div *ngIf="!load">
    <div class="col-md-12">
        <div class="d-flex justify-content-center carga">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <img src="../../../assets/img/logo.png" alt="" width="100">
        </div>
    </div>
</div>
<div class="container" *ngIf="load">
    <div class="row">
        <div class="col-12">
            <div class="col-md-5 mx-auto mt-5">
                <h3>Control de acceso.</h3>
                <div class="card border-dark mb-3">
                    <div class="card-header text-right">EJE GAMER STORE</div>
                    <div class="card-body text-dark">
                        <h4 class="card-title text-center">Login</h4>
                        <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
                            <div class="form-group">
                                <label for="email">E-mail</label>
                                <input type="email" class="form-control" placeholder="Ingrese su email" formControlName="email" [class.is-invalid]="emailNoValido">
                                <small class="eml" *ngIf="emailNoValido">Ingrese un email válido</small>
                            </div>
                            <div class="form-group">
                                <label for="password">Contraseña</label>
                                <div class="row" *ngIf="passwordView == false">
                                    <input type="password" class="form-control pass" formControlName="password" [class.is-invalid]="passwordNoValido">
                                    <button class="btn btn-light viewPass" (click)="viewPassActive()"><i class="fa fa-eye fa-lg"></i></button>
                                    <small class="spn" *ngIf="passwordNoValido">La contraseña debe ser de 8 caracteres mínimo.</small>
                                </div>
                                <div class="row" *ngIf="passwordView == true">
                                    <input type="text" class="form-control pass" formControlName="password" [class.is-invalid]="passwordNoValido">
                                    <button class="btn btn-light viewPass" (click)="viewPassInActive()"><i class="fa fa-eye-slash fa-lg"></i></button>
                                    <small class="spn" *ngIf="passwordNoValido">La contraseña debe ser de 8 caracteres mínimo.</small>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-success btn-block ingreso">Ingresar</button>
                        </form>
                        <div class="text-right mt-2">
                            <a [routerLink]="['/contrasena']" class="forgotPass">Olvidó su contraseña?</a>
                        </div>
                        <div>
                            <button class="btn btn-outline-success btn-block mt-2 count" [routerLink]="['/registro']">Crear cuenta</button>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
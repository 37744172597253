<div class="d-none d-lg-block d-xl-block cnt">
    <div class="d-flex ">
      <app-menu></app-menu>
      <div class="w-100">
        <app-head></app-head>
        <div id="contenido">
          <section class="py-3">
            <div class="container" style="margin-top: 3rem;">
                <h2>Actualizar producto:</h2>
                <hr>
                <div class="row animated fadeIn slow mt-5">
                    <div class="col-md-12">
                        <form [formGroup]="editProductForm" (ngSubmit)="editProduct(editProductForm.value)" autocomplete="off">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Nombre del juego / Producto:</label>
                                        <input type="text" formControlName="nombre" class="form-control" [class.is-invalid]="nombreNoValido" placeholder="Nombre del producto" name="nombre">
                                        <small *ngIf="nombreNoValido" class="text-danger">Debe ingresar el nombre</small>
                                    </div>
                                    <div class="form-group">
                                        <label>Tipo de juego</label>
                                        <select class="form-control" formControlName="tipo" name="tipo" required>                      
                                          <option value="Ps4">Ps4</option>
                                          <option value="Ps5">Ps5</option>
                                          <option value="fisico">Fisicos - Accesorios</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Imagen del juego / Producto:</label>
                                        <input type="file" formControlName="imageProd" class="form-control" (change)="handleImage($event)">
                                    </div>
                                    <div class="form-group" *ngIf="typePrd != 'fisico'">
                                        <label>Categoria:</label>
                                        <select class="form-control" formControlName="categoria" name="categoria" required>                      
                                            <option value="Fisicos">Fisicos</option>
                                            <option value="Acción">Acción</option>
                                            <option value="Deportes">Deportes</option>
                                            <option value="Infantil">Infantil</option>
                                            <option value="Aventura">Aventura</option>
                                            <option value="Terror">Terror</option>
                                            <option value="Lucha">Lucha</option>
                                        </select>
                                    </div>
                                    <div class="form-group" *ngIf="typePrd == 'fisico'">
                                        <label>Categoria:</label>
                                        <select class="form-control" formControlName="categoria" name="categoria" required>                      
                                            <option value="Controles">Controles</option>
                                            <option value="Diademas">Diademas</option>
                                            <option value="Ps5">Juegos PS5</option>
                                            <option value="Ps4">Juegos PS4</option>
                                            <option value="Accesorios">Accesorios</option>
                                            <option value="Consolas">Consolas</option>
                                            <option value="Cables">Cables</option>
                                            <option value="Skins">Skins</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Idioma:</label>
                                        <select class="form-control" formControlName="idioma" name="idioma" required>                      
                                          <option value="Multi Idioma">Multi Idioma</option>
                                          <option value="Textos Español - Audio Ingles">Textos Español - Audio Inglés</option>
                                          <option value="Ingles">Inglés</option>
                                          <option value="N/A">No Aplica</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Oferta del juego:</label>
                                        <input type="number" formControlName="oferta" class="form-control" placeholder="Oferta del juego" name="oferta">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Precio Principal:</label>
                                                <input type="number" formControlName="precio" class="form-control" name="precio" placeholder="Ingrese el precio" [class.is-invalid]="precioNoValido">
                                                <small *ngIf="precioNoValido" class="text-danger">Debe ingresar el precio principal</small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Cantidad Principal:</label>
                                                <input type="number" formControlName="cantPpal" class="form-control" name="cantPpal" placeholder="Ingrese la cantidad principal" [class.is-invalid]="preciosecNoValido">
                                                <small *ngIf="cantPpalNoValido" class="text-danger">Debe ingresar la cantidad principal</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Precio Secundario:</label>
                                                <input type="number" formControlName="preciosec" class="form-control" name="preciosec" placeholder="Ingrese el precio secundario" [class.is-invalid]="preciosecNoValido">
                                                <small *ngIf="preciosecNoValido" class="text-danger">Debe ingresar el precio secundario</small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Cantidad Secundario:</label>
                                                <input type="number" formControlName="cantSec" class="form-control" name="cantSec" placeholder="Ingrese la cantidad secundario" [class.is-invalid]="preciosecNoValido">
                                                <small *ngIf="cantSecNoValido" class="text-danger">Debe ingresar la cantidad secundario secundario</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Peso del juego:</label>
                                        <input type="text" formControlName="peso" class="form-control" name="peso" placeholder="Ingrese el peso en GB" [class.is-invalid]="pesoNoValido">
                                        <small *ngIf="pesoNoValido" class="text-danger">Debe ingresar el peso del juego</small>
                                    </div>
                                    <div class="col-md-12 imgBox">
                                        <img *ngIf="imgLoad == true" [src]="imageSrc" class="imgSrc" alt="">
                                        <img *ngIf="imgLoad == false" [src]="image" class="imgSrc" alt="">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label>Descripción del juego / producto:</label>
                                    <textarea class="form-control" formControlName="descripcion" name="descripcion" [class.is-invalid]="descripcionNoValido" cols="30" rows="5"></textarea>
                                    <small *ngIf="descripcionNoValido" class="text-danger">Debe ingresar la descripción del juego min 30 caracteres</small>
                                </div>
            
                            </div>
                            <div class="form-group text-center mt-3">
                                <button type="submit" class="btn btn-outline-success w-50">
                                    <i class="fa fa-save"> Guardar</i>
                                </button>
                                <hr>
                            </div>
                        </form>
                        <div class="col-12 mt-3">
                            <div class="row">
                                <div class="col-4 text-center cntdor">
                                    <label class="custom-file imgchange" id="customFile">
                                        <img *ngIf="imagen1" [src]="imagen1" width="100" alt="">
                                        <img *ngIf="!imagen1" src="../../../assets/img/picture.png" width="100" alt="">
                                        <input type="file" class="custom-file-input" (change)="handleImage1($event)">
                                        <span class="custom-file-control form-control-file"></span>
                                    </label>
                                    <div class="text-center">
                                        <h5>Imagen 1</h5>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent1 | async) + '%'"></div>
                                    </div>
                                </div>
                                <div class="col-4 text-center cntdor">
                                    <label class="custom-file imgchange" id="customFile">
                                        <img *ngIf="imagen2" [src]="imagen2" width="100" alt="">
                                        <img *ngIf="!imagen2" src="../../../assets/img/picture.png" width="100" alt="">
                                        <input type="file" class="custom-file-input" (change)="handleImage2($event)">
                                        <span class="custom-file-control form-control-file"></span>
                                    </label>
                                    <div class="text-center">
                                        <h5>Imagen 2</h5>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent2 | async) + '%'"></div>
                                    </div>
                                </div>
                                <div class="col-4 text-center cntdor">
                                    <label class="custom-file imgchange" id="customFile">
                                        <img *ngIf="imagen3" [src]="imagen3" width="100" alt="">
                                        <img *ngIf="!imagen3" src="../../../assets/img/picture.png" width="100" alt="">
                                        <input type="file" class="custom-file-input" (change)="handleImage3($event)">
                                        <span class="custom-file-control form-control-file"></span>
                                    </label>
                                    <div class="text-center">
                                        <h5>Imagen 3</h5>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent3 | async) + '%'"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-3 text-center cntdor">
                                    <label class="custom-file imgchange" id="customFile">
                                        <img *ngIf="mini2" [src]="mini2" width="70" alt="">
                                        <img *ngIf="!mini2" src="../../../assets/img/picture.png" width="50" alt="">
                                        <input type="file" class="custom-file-input" (change)="handleMini2($event)">
                                        <span class="custom-file-control form-control-file"></span>
                                    </label>
                                    <div class="text-center">
                                        <h5>Miniatura 1</h5>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent4 | async) + '%'"></div>
                                    </div>
                                </div>
            
                                <div class="col-3 text-center cntdor">
                                    <label class="custom-file imgchange" id="customFile">
                                        <img *ngIf="mini" [src]="mini" width="70" alt="">
                                    <img *ngIf="!mini" src="../../../assets/img/picture.png" width="50" alt="">
                                        <input type="file" class="custom-file-input" (change)="handleMini($event)">
                                        <span class="custom-file-control form-control-file"></span>
                                    </label>
                                    <div class="text-center">
                                        <h5>Miniatura 2</h5>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent5 | async) + '%'"></div>
                                    </div>
                                </div>
            
                                <div class="col-3 text-center cntdor">
                                    <label class="custom-file imgchange" id="customFile">
                                        <img *ngIf="mini1" [src]="mini1" width="70" alt="">
                                        <img *ngIf="!mini1" src="../../../assets/img/picture.png" width="50" alt="">
                                        <input type="file" class="custom-file-input" (change)="handleMini1($event)">
                                        <span class="custom-file-control form-control-file"></span>
                                    </label>
                                    <div class="text-center">
                                        <h5>Miniatura 3</h5>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent6 | async) + '%'"></div>
                                    </div>
                                </div>
            
                                <div class="col-3 text-center cntdor">
                                    <label class="custom-file imgchange" id="customFile">
                                        <img *ngIf="mini4" [src]="mini4" width="70" alt="">
                                        <img *ngIf="!mini4" src="../../../assets/img/picture.png" width="50" alt="">
                                        <input type="file" class="custom-file-input" (change)="handleMini4($event)">
                                        <span class="custom-file-control form-control-file"></span>
                                    </label>
                                    <div class="text-center">
                                        <h5>Miniatura Principal</h5>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadPercent7 | async) + '%'"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center mt-4">
                                <button class="btn btn-primary btn w-50" (click)="updateGalery()">Actualizar Galería</button>
                            </div>
                        </div>
                    </div>
                </div><br>
            </div>
          </section>
         </div>
      </div>
    </div>
</div> 

<div class="d-none d-lg-block d-xl-block cnt">
    <div class="d-flex ">
      <app-menu></app-menu>
      <div class="w-100">
        <app-head></app-head>
        <div id="contenido">
          <section class="py-3">
            <div class="container mt-5">
                <h3><img src="../../../assets/img/mensaje.png" width="70" alt=""> Gestión de Comentarios:</h3>
                <hr>
                <br>
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Usuario</th>
                            <th scope="col">Comentario</th>
                            <th class="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of lista">
                            <td>{{item.usuario}}</td>
                            <td>{{item.coment}}</td>
                            <td class="text-center">
                                <button (click)="aceptarComentario(item.id)" class="btn btn-success mr-1"><i class="fa fa-check"></i></button>
                                <button (click)="eliminarComentario(item.id)" class="btn btn-danger"><i class="fa fa-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </section>
         </div>
      </div>
    </div>
</div> 

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBtl0v5XYwIk6_q1TzWAaGYejfx1kSti4k',
    authDomain: 'ejeplay-7a38e.firebaseapp.com',
    databaseURL: 'https://ejeplay-7a38e.firebaseio.com',
    projectId: 'ejeplay-7a38e',
    storageBucket: 'ejeplay-7a38e.appspot.com',
    messagingSenderId: '1048392307774',
    appId: '1:1048392307774:web:7f494ad591571ccf80ed6f',
    measurementId: 'G-E0YM5T67QN'
  }
};
